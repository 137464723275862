import React, { FC, useEffect, useState, useRef } from "react";
import Logo from "assets/logo-sbux-grab.png";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import Button from "components/Button";
import generateUUID from "utils/generateUUID";
import { fetcher } from "utils/fetcher";
import {
  FORGET_PASS_URL,
  LINKING_URL,
  OAUTH_URL,
  REGISTER_URL,
} from "contants";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type FormStateType = {
  email: string;
  password: string;
  keep_logged_in: boolean;
};

type PassedInfo = {
  grabId: string | null;
  merhantId: string | null;
};

const Signin: FC = () => {
  const navigate = useNavigate();

  let recaptchaRef: any = useRef();

  const captchaKey: any = process.env.REACT_APP_CAPTCHA_KEY ?? "";
  const grabInfo: any = (window as any)?.grabWebView?.passInfoToWebView();
  console.log(grabInfo, "<< grab info");

  const [passedInfo, setPassedInfo] = useState<PassedInfo>({
    grabId: null,
    merhantId: null,
  });
  const [token, setToken] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormStateType>({
    email: "",
    password: "",
    keep_logged_in: false,
  });
  const [captchToken, setCaptchaToken] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    if (name === "keep_logged_in") {
      setFormState({ ...formState, [name]: checked });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const handleSignin = async () => {
    setLoading(true);
    try {
      const id = generateUUID();
      const payload = {
        requestId: id,
        requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        channelId: "GRAB",
        account: {
          email: formState.email,
          password: formState.password,
          grabId: passedInfo.grabId ?? "",
          merchantId: passedInfo.merhantId ?? "",
        },
        token: captchToken,
      };
      const res = await fetcher({
        url: LINKING_URL,
        headers: {
          method: "POST",
          data: payload,
          headers: { Authorization: "Bearer " + token },
        },
      });
      (window as any)?.grabWebView?.webViewCallback(
        JSON.stringify({
          action: "PASS_LINKING_RESULT",
          scope: "PARTNER",
          metadata: {
            behavior: "LINKING",
            isSuccess: res.responseDesc === "Success" ? true : false,
            message: `Grab and Starbucks user accounts linking ${
              res.responseDesc === "Success" ? "succeed" : "failed"
            }.`,
          },
        })
      );
      console.log(res, "<<< linking response");
      if (res.responseCode === "000000") {
        console.log("<<< success");
        toast.success(res.responseDesc);
        navigate("/all-set");
      } else {
        console.log(res, "<<< failed");
        toast.error(res.responseDesc);
      }
    } catch (error: any) {
      console.log(error, "<< error linking");
      toast.error(error?.data?.responseDesc);
    } finally {
      setLoading(false);
      recaptchaRef?.current.reset();
      setCaptchaToken(null);
    }
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    const id = generateUUID();
    const payload = {
      requestId: id,
      requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
      channelId: "GRAB",
      account: {
        email: "",
      },
    };

    const res = await fetcher({
      url: FORGET_PASS_URL,
      headers: {
        method: "POST",
        data: payload,
        headers: { Authorization: "Bearer " + token },
      },
    });
    const newWindow = window.open(res.forgetPasswordUrl, "forgotPass");

    if (newWindow !== null) {
      setLoading(false);
    } else {
      window.location.href = res.forgetPasswordUrl;
      setLoading(false);
    }
  };

  const handleJoin = async () => {
    setLoading(true);
    const id = generateUUID();
    const payload = {
      requestId: id,
      requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
      channelId: "GRAB",
    };

    const res = await fetcher({
      url: REGISTER_URL,
      headers: {
        method: "POST",
        data: payload,
        headers: { Authorization: "Bearer " + token },
      },
    });
    const newWindow = window.open(res.registrationUrl, "register");
    if (newWindow !== null) {
      setLoading(false);
    } else {
      window.location.href = res.registrationUrl;
      setLoading(false);
    }
  };

  // oauth
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const res = await fetcher({
          url: OAUTH_URL,
          headers: {
            method: "POST",
            data: {
              client_id: process.env.REACT_APP_CLIENT_ID,
              client_secret: process.env.REACT_APP_CLIENT_SECRET,
              grant_type: "client_credentials",
              scope: "WEBVIEW",
            },
          },
        });

        setToken(res.access_token);
      } catch (err: any) {
        toast.error(err.data.responseDesc);
      }
    };

    fetchToken();
  }, []);

  // grab info
  useEffect(() => {
    if (grabInfo) {
      console.log(grabInfo);
      const parsedInfo = JSON.parse(grabInfo);
      setPassedInfo({
        grabId: parsedInfo?.grabUserId,
        merhantId: parsedInfo?.merchantId,
      });
    }
  }, [grabInfo]);

  return (
    <div className="flex flex-col justify-center items-center gap-10 bg-white max-w-[500px] px-4 pb-4 min-h-[100vh]">
      <div className="flex flex-col justify-center items-center">
        <img src={Logo} alt="logo-sbux-grab" />
        <p className="text-2xl">Link your membership with grab</p>
      </div>
      <div className="flex flex-col gap-4 w-full">
        <input
          className="border-0 border-b-2 text-sm focus:ring-0 focus:border-brand"
          type="email"
          name="email"
          placeholder="Email address*"
          autoComplete="off"
          required
          onChange={handleChange}
          disabled={loading}
        />
        <input
          className="border-0 border-b-2 text-sm focus:ring-0 focus:border-brand"
          type="password"
          name="password"
          placeholder="Password*"
          autoComplete="off"
          required
          onChange={handleChange}
          disabled={loading}
        />

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={captchaKey}
          onChange={(token) => setCaptchaToken(token)}
          onExpired={() => setCaptchaToken(null)}
        />

        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <input
              type="checkbox"
              checked={formState.keep_logged_in}
              name="keep_logged_in"
              onChange={handleChange}
              className="text-brand focus:ring-0"
              id="checkbox"
              disabled={loading}
            />
            <p className="text-xs">keep me logged in</p>
          </div>
          <p
            className="text-xs text-brand cursor-pointer"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </p>
        </div>

        <Button
          label="Sign In"
          disabled={
            !formState.email || !formState.password || loading || !captchToken
          }
          onClick={handleSignin}
        />
      </div>

      <div className="flex flex-col justify-center items-center bg-light-green rounded-xl w-full p-4 gap-2">
        <p className="font-bold text-appron-green text-sm">
          JOIN STARBUCKS REWARDS
        </p>
        <p className="text-xs text-center max-w-[80%]">
          Join Starbucks Rewards to earn free food and drinks, get free refills,
          pay and order with your phone, and more.
        </p>

        <Button
          label="Join now"
          onClick={handleJoin}
          variant="outlined"
          fullWidth={false}
          disabled={loading}
        />
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
          }}
        >
          <RotatingLines
            strokeColor="#006241"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Signin;
