import React from "react";
import { useNavigate } from "react-router-dom";
import LogoSbux from "assets/logo-sbux.png";
import Exclamation from "assets/exclamation-icon.svg";
import Button from "components/Button";

const InactivePage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-between w-full h-screen gap-10 bg-white max-w-[500px]">
      <div>
        <img src={LogoSbux} alt="logo-sbux" className="mt-[-20%]" />

        <div className="flex justify-center px-9 text-sm mt-[-25%]">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center gap-2">
              <img src={Exclamation} alt="check-icon" width={25} />
              <p>
                This Account is no longer active, Please use a new Starbucks
                account
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center px-4 pb-4">
        <Button
          label="Link Account"
          onClick={() => navigate("/")}
          rounded="sm"
          baseColor="green"
        />
      </div>
    </div>
  );
};

export default InactivePage;
