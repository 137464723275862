import React, { FC } from "react";

interface ButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  variant?: "filled" | "outlined";
  rounded?: "full" | "xl" | "sm";
  baseColor?: "green" | "orange";
  fullWidth?: boolean;
}

const Button: FC<ButtonProps> = ({
  label,
  onClick,
  disabled,
  variant = "filled",
  rounded = "full",
  baseColor = "green",
  fullWidth = true,
}) => {
  const classNameFilled = `${
    disabled
      ? "bg-gray-500"
      : baseColor === "green"
      ? "bg-brand"
      : `bg-orange-700`
  } text-white ${
    rounded === "full" ? "rounded-full" : `rounded-${rounded}`
  } p-2 mt-10 ${fullWidth ? `w-full` : ""}`;

  const classNameOutlined = `text-sm border ${
    disabled
      ? `border-${baseColor}-500 text-${baseColor}-500 `
      : `border-appron-green text-appron-green `
  }px-2 py-1 ${rounded === "full" ? "rounded-full" : `rounded-${rounded}`} ${
    fullWidth ? `w-full` : ""
  } font-bold`;

  return (
    <button
      className={variant === "filled" ? classNameFilled : classNameOutlined}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
