import React, { useState } from "react";
import moment from "moment";
import generateUUID from "utils/generateUUID";
import LogoSbux from "assets/logo-sbux.png";
import Checklist from "assets/check.png";
import Button from "components/Button";
import { fetcher } from "utils/fetcher";
import { UNLINK_URL } from "contants";
import { useSearchParams } from "react-router-dom";

const LinkingPage = () => {
  const [params] = useSearchParams();
  const token = params.get("id_token");
  const [loading, setLoading] = useState<boolean>(false);

  const unlink = () => {
    setLoading(true);
    const payload = {
      requestId: generateUUID(),
      requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
      channelId: "GRAB",
      customerToken: "Jkshady78ya8sjio2ijeuwxd",
    };

    const res = fetcher({
      url: UNLINK_URL,
      headers: {
        method: "POST",
        data: payload,
        headers: { Authorization: "Bearer " + token },
      },
    });
    console.log(res);

    setLoading(false);
  };

  return (
    <div className="flex flex-col justify-between w-full h-screen gap-10 bg-white max-w-[500px]">
      <div>
        <img src={LogoSbux} alt="logo-sbux" className="mt-[-20%]" />

        <div className="flex justify-center px-9 text-sm mt-[-25%]">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center gap-2">
              <img src={Checklist} alt="check-icon" width={25} />
              <p>
                Stars (Loyaly Points) will be automatically added to your
                Starbucks Rewards account.
              </p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <img src={Checklist} alt="check-icon" width={25} />
              <p>
                Earn 1 Star for every IDR 10K spent on Starbucks products when
                you order through Grab.
              </p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <img src={Checklist} alt="check-icon" width={25} />
              <p>
                You may earn extra stars on your birthday and enjoy other
                promotions from Starbucks Rewards.
              </p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <img src={Checklist} alt="check-icon" width={25} />
              <p>
                Collect your Stars and redeem for various Rewards in you
                Starbucks Indonesia App.
              </p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <img src={Checklist} alt="check-icon" width={25} />
              <p>
                Stars earned will be reflected in Starbucks App Purchase history
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center px-4 pb-4">
        <Button
          label="Remove Account"
          onClick={unlink}
          rounded="sm"
          baseColor="orange"
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default LinkingPage;
