import React from "react";
import { Routes, Route } from "react-router-dom";

import signin from "./signin";
import AllSetPage from "./all-set";
import linking from "./linking";
import inactive from "./inactive";

const appRoutes: any[] = [
  { ...signin },
  { ...AllSetPage },
  { ...linking },
  { ...inactive },
];

const RenderRoutes = () => (
  <>
    <Routes>
      {appRoutes.map((route: any, i: number) => (
        <Route {...route} key={i} />
      ))}
    </Routes>
  </>
);

export default RenderRoutes;
