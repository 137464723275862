import React from "react";
import Logo from "assets/logo-sbux-grab.png";
import IconCoffe from "assets/icon-cofee.png";
import IconCoffe2 from "assets/icon-coffee-2.png";
import IconBox from "assets/icon-box.png";

const AllSetPage = () => {
  return (
    <div className="flex flex-col items-center h-screen gap-10 bg-white max-w-[500px] px-4">
      <div className="flex flex-col justify-center items-center">
        <img src={Logo} alt="logo-sbux-grab" />
        <p className="text-2xl">All set</p>
      </div>

      <div className="flex flex-col justify-center items-center">
        <p className="text-center text-sm">
          Sending you back to the Grab app to enjoy your member benefits
        </p>
        <div className="flex flex-row items-baseline">
          <div>
            <img src={IconCoffe} width={120} height={120} alt="icon-coffee" />
            <p className="text-xs text-center max-w-[90%]">
              Free drink with stars redemption
            </p>
          </div>
          <div>
            <img src={IconCoffe2} width={120} height={120} alt="icon-coffee" />
            <p className="text-xs text-center max-w-[90%]">
              Birthday treats on the house
            </p>
          </div>
          <div>
            <img src={IconBox} width={120} height={120} alt="icon-coffee" />
            <p className="text-xs text-center max-w-[90%]">
              Exclusive member offers
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSetPage;
