import axios from "axios";

interface IFetcher {
  url: string;
  headers: object;
}

export const fetcher = async ({ url, headers }: IFetcher) => {
  let header: object = {
    ...headers,
    url: url,
    withCredentials: "true",
  };
  try {
    let data = await axios.request(header);
    return data.data;
  } catch (err: any) {
    throw err.response;
  }
};
